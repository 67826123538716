const defaultBranding = {
    // type of product - eg, "forms"
    ProductType: "coupon",
    // menu text to select product search tab - eg, "Forms Search"
    SearchLabel: "Coupon Search",
    // search button action label - eg, "Search Forms"
    SearchButtonText: "",
    // meta keyword specified in manifest (might not be the same as product type) - eg, "forms"
    MetaSearchKeyword: "coupon",
    // example searches on first run - eg, "Taxes, Immigration, US"
    SearchExample: "Macys, JC Penny, Mobile, Television",
    // eg, "Search form titles or tags"
    AutocompletePlaceholder: "Search",
    // eg, "Search our database for forms by typing in a form query in the search box above."
    IntroLI1:
        "Search thousands of printable in-store coupons & grocery coupons",
    // eg, "You can also browse our forms database by 'tag' located on the left side menu of the page."
    IntroLI2:
        "for popular brands in our Coupon Database. You can even search by store!",
    FirstRunSettings: false,
};

const windowBranding = window.branding || {};

const PRODUCT_SEARCH_PROVIDERS = {
    default: {
        name: windowBranding.SearchEngineName,
        id: "default",
        isDefault: true,
    },
    groupon: {
        name: "Groupon",
        id: "groupon",
        isDefault: false,
        url: "https://www.groupon.com/?query={{QUERY}}",
    },
    techbargains: {
        name: "Tech Bargains",
        id: "techbargains",
        isDefault: false,
        url: "https://www.techbargains.com/search?search={{QUERY}}",
    },
};

const altSearchProvidersUrls = function(query) {
    const altProviders = Object.entries(PRODUCT_SEARCH_PROVIDERS).filter(
        ([key, value]) => !value.isDefault
    );
    return altProviders.map(([key, value]) => {
        return {
            name: value.name,
            url: value.url.replace("{{QUERY}}", query),
        };
    });
};

const DEFAULT_PRODUCT_SEARCH_PROVIDER = PRODUCT_SEARCH_PROVIDERS.default.id;

function getBranding() {
    return {
        ...defaultBranding,
        ...windowBranding,
    };
}

export {
    getBranding,
    PRODUCT_SEARCH_PROVIDERS,
    altSearchProvidersUrls,
    DEFAULT_PRODUCT_SEARCH_PROVIDER,
};
