<template>
    <!--main wrapper-->
    <div class="serp-wrapper">
        <!-- Bookmark Popup  -->
        <first-run
            :which="whichPop"
            :onClose="onCloseHelp"
            :onNext="onNextPop"
        />
        <!--header wrapper-->
        <div class="header-wrapper">
            <!--nav-->
            <div id="serp-nav">
                <div
                    id="job-btn"
                    class="serp-nav-item"
                    :class="{ active: productSearch }"
                    @click="toProductSearch()"
                >
                    {{ branding.SearchLabel }}
                </div>
                <div
                    id="web-btn"
                    class="serp-nav-item"
                    :class="{ active: !this.productSearch }"
                    @click="toWebSearch()"
                >
                    Web Search
                </div>
            </div>
            <!--End of nav-->
            <!--header-->
            <div class="serp-header">
                <div class="serp-logo"><img :src="branding.Logo" /></div>
                <div class="thisSearch" v-show="productSearch">
                    <div class="search pad-right">
                        <auto-complete-input
                            v-model="query"
                            ref="queryInput"
                            :placeholder="branding.AutocompletePlaceholder"
                            @selected="onSugSelected()"
                            @return="onSugSelected()"
                        />
                        <div class="s-icon">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <div class="error" v-if="queryError">
                            This field is required, please enter
                        </div>
                    </div>
                    <button id="btn-search" @click="onSugSelected()">
                        {{ branding.SearchLabel }}
                    </button>
                </div>
                <div class="webSearch" v-show="!productSearch">
                    <div class="search web">
                        <input
                            ref="webSearch"
                            id=""
                            placeholder="Search the web"
                            v-model="wQuery"
                            @keydown.enter="startWebSearch()"
                            autofocus
                        />
                        <div class="s-icon">
                            <i class="fa fa-globe" aria-hidden="true"></i>
                        </div>
                        <div class="error" v-if="queryError">
                            This field is required, please enter
                        </div>
                    </div>
                    <button id="btn-search" @click="startWebSearch()">
                        Web Search
                    </button>
                </div>
            </div>
            <!--End of Header-->
        </div>
        <!--End of header wrapper-->
        <!--serp results-->
        <div class="bodyWrapper">
            <div class="serp-results">
                <!--Left col-->
                <div class="serp-results-left">
                    <!-----First Run Add on------>
                    <intro-box v-show="!hasSearchResults" />
                    <!-----End of First Run Add on------>
                    <div class="serp-stats" v-show="hasSearchResults">
                        Showing {{ filteredSearchResults.length }}
                        <span> of </span> {{ totalRecords }}
                        {{ branding.ProductType }} for
                        <span class="kw"> {{ searchTerm }}</span>
                        <span v-if="filterTag" class="filter">{{
                            filterTag
                        }}</span>
                        <span v-if="filterStore" class="filter">{{
                            filterStore
                        }}</span>
                    </div>
                    <!--NoResults-->
                    <div
                        class="serp-noresults"
                        v-show="
                            !hasSearchResults &&
                                searchTerm != '' &&
                                searchCompleted
                        "
                    >
                        <h3>
                            The search
                            <span class="kw">{{ searchTerm }}</span> did not
                            match any {{ branding.ProductType }}. Try the
                            suggestions below or type a new query above.
                        </h3>
                        <ul>
                            <li>Check your spelling.</li>
                            <li>Try more general keywords</li>
                            <li>Replace abbreviations with the entire word</li>
                        </ul>
                        <h2>Still no results!</h2>
                        <h3 class="bs">
                            We try our best to keep up our database up to date,
                            but unfortunately with hundreds of new
                            {{ branding.ProductType }} released every day, it is
                            sometimes hard to account for all new updates. Try
                            the links below to some other resources that may
                            help with your search.
                        </h3>
                        <br />
                        <p v-for="{ name, url } in altSearchUrls">
                            <a
                                class="other-res"
                                :href="url"
                                :title="name"
                                target="_blank"
                                >{{ name }}</a
                            >
                        </p>
                    </div>
                    <!-- End of NoResults-->
                    <div v-show="hasSearchResults">
                        <!--results-->
                        <div
                            class="srl has-image"
                            v-for="result in filteredSearchResults"
                            @click.prevent="
                                openUrl(result.url, true, false, false)
                            "
                        >
                            <div class="srl-img">
                                <img
                                    :src="
                                        result.image[0] ||
                                            branding.FallbackResultIcon
                                    "
                                    @error="replaceImg($event)"
                                />
                            </div>
                            <div class="srl-info">
                                <h3 class="srl-title">
                                    <a :href="result.url" target="_blank">{{
                                        result.title
                                    }}</a>
                                </h3>
                                <div
                                    v-show="result.description"
                                    class="srl-description"
                                >
                                    {{ result.description }}
                                </div>
                                <div
                                    v-show="result.domain"
                                    :key=""
                                    class="srl-domain"
                                >
                                    {{ result.domain }}
                                </div>
                            </div>
                        </div>
                        <div class="is-end-container">
                            <div class="is-end" v-if="isEnd">
                                NO MORE RESULTS
                            </div>
                        </div>
                        <!--end of results-->
                    </div>
                    <!--Browse Panel-->
                    <!--End of left col-->
                </div>
                <!--Right col -->
                <div class="rightCol">
                    <div class="rightColInner">
                        <!--Filter-->
                        <div
                            class="module-right filter"
                            v-if="hasSearchResults"
                        >
                            <h2>
                                <i class="fa fa-tag" aria-hidden="true"></i
                                >Filter by tag:
                            </h2>
                            <i
                                class="fa arrows"
                                v-on:click="toggleTags"
                                :class="{
                                    'fa-chevron-down': !showTagFilters,
                                    'fa-chevron-up': showTagFilters,
                                }"
                                aria-hidden="true"
                            ></i>
                            <div
                                class="category custom-select"
                                v-if="showTagFilters"
                            >
                                <ul id="tags">
                                    <li>
                                        <a
                                            :class="{ active: !filterTag }"
                                            @click="resetFilterTag()"
                                            title="All"
                                            >All</a
                                        >
                                    </li>
                                    <template v-for="tag in tags">
                                        <li
                                            :class="{
                                                active: filterTag === tag,
                                            }"
                                        >
                                            <a
                                                @click="
                                                    filterTagResults(
                                                        $event,
                                                        tag
                                                    )
                                                "
                                                :title="tag"
                                                >{{ tag }}</a
                                            >
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                        <!--End of filter-->
                        <!--Store Filter-->
                        <div
                            class="module-right filter"
                            v-if="hasSearchResults"
                        >
                            <h2>
                                <i
                                    class="fa fa-shopping-basket"
                                    aria-hidden="true"
                                ></i
                                >Filter by Stores:
                            </h2>
                            <i
                                class="fa arrows"
                                v-on:click="toggleStores"
                                aria-hidden="true"
                                :class="{
                                    'fa-chevron-down': !showStoreFilters,
                                    'fa-chevron-up': showStoreFilters,
                                }"
                            ></i>
                            <div
                                class="category custom-select"
                                v-if="showStoreFilters"
                            >
                                <ul id="tags">
                                    <li>
                                        <a
                                            :class="{ active: !filterTag }"
                                            @click="resetFilterStore()"
                                            title="All"
                                            >All</a
                                        >
                                    </li>
                                    <template v-for="store in stores">
                                        <li
                                            :class="{
                                                active: filterTag === store,
                                            }"
                                        >
                                            <a
                                                @click="
                                                    filterStoreResults(
                                                        $event,
                                                        store
                                                    )
                                                "
                                                :title="store"
                                                >{{ store }}</a
                                            >
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                        <!--End of store filter-->
                        <!--Search History-->
                        <div class="module-right side-list history">
                            <h2>
                                <i class="fa fa-history" aria-hidden="true"></i
                                >Recent searches
                            </h2>
                            <div
                                class="list"
                                v-for="recent in recentSearches"
                                @click="doRecentSearch(recent)"
                            >
                                {{ recent }}
                            </div>
                        </div>
                        <div class="to-top-btn">
                            <button
                                id="top-btn"
                                @click="goToTop()"
                                v-if="this.showTopBtn"
                            >
                                <i class="fa fa-chevron-up"></i>
                            </button>
                        </div>
                        <!--end of search history-->
                    </div>
                </div>
                <!--End of Right col-->
            </div>
        </div>
        <!--End of resultes-->
        <!--footer-->
        <footer>
            <div class="serp-footer">
                <div class="logoFooter">
                    <img :src="branding.Logo" /><span
                        ><span id="brandDetails"
                            ><i class="fa fa-copyright" aria-hidden="true"></i>
                            {{ branding.Trademark }}</span
                        >
                    </span>
                </div>
                <ul>
                    <li>
                        <a
                            :href="
                                `https://${
                                    branding.RootDomain
                                }/terms-of-service/`
                            "
                            >Terms of Service
                        </a>
                    </li>
                    <li>
                        <a
                            :href="
                                `https://${branding.RootDomain}/privacy-policy/`
                            "
                            >Privacy</a
                        >
                    </li>
                    <li>
                        <a :href="`https://${branding.RootDomain}/contact-us/`"
                            >Contact Us</a
                        >
                    </li>
                    <li>
                        <a
                            :href="
                                `https://${
                                    branding.RootDomain
                                }/privacy-policy/#cali-consumer`
                            "
                            target="_blank"
                            >Do Not Sell My Info</a
                        >
                    </li>
                </ul>
            </div>
        </footer>
        <!--end of footer-->
    </div>
    <!-- End of main wrapper -->
</template>

<script>
import bp from "babel-polyfill";
import queryString from "query-string";
import "whatwg-fetch";
import Humanize from "humanize-plus";
import { getSetting, setSetting, getGuid } from "../storage";
import { WEB_SEARCH_URL, openUrl, SEARCH_API_URL } from "../urls";
import FirstRun from "../components/FirstRun.vue";
import AutoCompleteInput from "../components/AutoCompleteInput.vue";
import IntroBox from "../components/IntroBox.vue";
import {
    getBranding,
    PRODUCT_SEARCH_PROVIDERS,
    altSearchProvidersUrls,
} from "../branding";
import { documentReady } from "../common";
import "../analytics";

function validatePop(name) {
    switch (name) {
        case "intro":
        case "i":
            return "intro";
        case "t":
        case "tutorial":
            return "tutorial";
        case "it":
            return "intro-tutorial";
    }

    return false;
}

export default {
    name: "serp",
    components: {
        "first-run": FirstRun,
        "auto-complete-input": AutoCompleteInput,
        "intro-box": IntroBox,
    },
    data() {
        const qs = queryString.parse(window.location.search);
        return {
            openUrl,
            recentSearches: [],
            productSearch: true,
            filterTag: null,
            filterStore: null,
            records: [],
            searchCompleted: false,
            query: "",
            wQuery: "",
            queryError: false,
            searchTerm: "",
            guid: qs.guid,
            productEngine: qs.engine,
            whichPop: validatePop(qs.pop) || false,
            branding: getBranding(),
            isEnd: false,
            totalRecords: 0,
            totalPages: 0,
            currentPage: 1,
            perPageNo: 10,
            trigger: 50,
            isFetching: false,
            catRecords: [],
            tags: [],
            stores: [],
            showStoreFilters: true,
            showTagFilters: true,
            showTopBtn: false,
        };
    },
    mounted() {
        documentReady.then(async () => {
            this.recentSearches =
                JSON.parse(getSetting("recentSearches")) || [];

            try {
                this.guid = await getGuid();
            } catch (e) {
                // no extension or guid query paraneter
            }
            this.$refs.queryInput.focus();
            this.handleSearchUrl();
            this.scrollCheck();
        });
    },
    async created() {
        window.onpopstate = () => {
            this.handleSearchUrl();
        };
    },
    methods: {
        replaceImg(e) {
            if (this.branding && this.branding.FallbackResultIcon)
                e.target.src = this.branding.FallbackResultIcon;
        },
        goToTop() {
            window.scrollTo(0, 0);
        },
        toggleStores() {
            this.showStoreFilters = !this.showStoreFilters;
        },
        toggleTags() {
            this.showTagFilters = !this.showTagFilters;
        },
        scrollCheck() {
            window.onscroll = ev => {
                this.showTopBtn = window.scrollY > 0;
                if (
                    window.innerHeight + window.scrollY >=
                    document.body.offsetHeight + this.trigger
                ) {
                    this.getNextResults();
                }
            };
        },
        async getNextResults() {
            if (this.currentPage < this.totalPages && !this.isFetching) {
                this.currentPage += 1;

                const newRecords = await this.makeNextCall();
                if (newRecords) {
                    this.records = this.records.concat(newRecords);
                }
                if (this.currentPage >= this.totalPages - 1) {
                    this.isEnd = true;
                }
            }
            this.isFetching = false;
        },
        async makeNextCall() {
            this.isFetching = true;
            const qs = {
                q: this.query,
                perPage: this.perPageNo,
                pageNo: this.currentPage,
            };

            const recordsReq = await fetch(
                `${SEARCH_API_URL}?${queryString.stringify(qs)}`
            );
            if (recordsReq.ok) {
                const recordsRes = await recordsReq.json();
                return recordsRes.main.records;
            }
            this.isFetching = false;
        },
        async getCategories() {
            const tags = new Set();
            const stores = new Set();
            const qs = {
                q: this.query,
                perPage: 500,
                pageNo: 0,
            };
            const recordsReq = await fetch(
                `${SEARCH_API_URL}?${queryString.stringify(qs)}`
            );
            if (recordsReq.ok) {
                const recordsRes = await recordsReq.json();
                if (recordsRes && recordsRes.main) {
                    this.catRecords = recordsRes.main.records;
                    this.catRecords.forEach(rec => {
                        if (rec) {
                            if (rec.resultCategory1) {
                                tags.add(rec.resultCategory1);
                            }
                            if (rec.resultEntity) {
                                stores.add(rec.resultEntity);
                            }
                        }
                    });
                    this.tags = Array.from(tags).sort();
                    this.stores = Array.from(stores).sort();
                }
            }
        },
        async startWebSearch() {
            const searchParams = {
                k: this.wQuery,
                guid: this.guid,
            };
            await openUrl(
                `${WEB_SEARCH_URL}?${queryString.stringify(searchParams)}`,
                false
            );
        },
        validateSearch(withFocus) {
            const { query } = this;
            let errs = false;
            if (this.query == "") {
                errs = errs || {};
                errs.query = "This field is required, Please enter";
            }
            this.queryError = errs;
            if (withFocus) {
                if (errs.query) this.$refs.queryInput.focus();
            }
            return errs === false;
        },
        addToBrowserHistory() {
            let qs = {
                ...queryString.parse(window.location.search),
                q: this.query,
            };
            // Add guid if there is one.
            if (this.guid) {
                qs.guid = this.guid;
            }
            qs = queryString.stringify(qs);

            const newUrl = `${this.baseUrl}?${qs}`;
            window.history.pushState(
                { query: this.query },
                `${this.query}`,
                newUrl
            );
        },
        async doSearch(term, addToHistory = true) {
            window.scrollTo(0, 0);
            this.records = [];
            this.isEnd = false;
            this.currentPage = 0;
            if (
                this.productEngine &&
                PRODUCT_SEARCH_PROVIDERS[this.productEngine].url
            ) {
                await openUrl(
                    PRODUCT_SEARCH_PROVIDERS[this.productEngine].url.replace(
                        "{{QUERY}}",
                        term
                    ),
                    false
                );
            } else {
                if (addToHistory && term) {
                    this.addToBrowserHistory();
                    this.recentSearches.length = 5;
                    this.recentSearches.splice(
                        this.recentSearches.indexOf(term),
                        1
                    );
                    this.recentSearches.unshift(term);
                    setSetting(
                        "recentSearches",
                        JSON.stringify(this.recentSearches)
                    );
                }
                this.filterTag = null;
                this.filterStore = null;
                this.searchCompleted = false;
                const qs = {
                    q: term,
                    perPage: this.perPageNo,
                    pageNo: this.currentPage,
                };
                const recordsReq = await fetch(
                    `${SEARCH_API_URL}?${queryString.stringify(qs)}`
                );
                if (recordsReq.ok) {
                    const recordsRes = await recordsReq.json();
                    if (recordsRes && recordsRes.main) {
                        if (term && recordsRes.main.records) {
                            this.records = recordsRes.main.records;
                            this.searchTerm = recordsRes.main.query;
                            this.totalPages = recordsRes.main.numPages;
                            this.totalRecords = recordsRes.main.total;
                        }
                    }
                    this.searchCompleted = true;
                }
            }
        },
        filterTagResults(ev, tag) {
            ev.preventDefault();
            this.filterStore = null;
            this.filterTag = tag;
        },
        resetFilterTag() {
            this.filterTag = null;
        },
        filterStoreResults(ev, tag) {
            ev.preventDefault();
            this.filterTag = null;
            this.filterStore = tag;
        },
        resetFilterStore() {
            this.filterStore = null;
        },
        toWebSearch() {
            this.productSearch = false;
            this.$nextTick(() => this.$refs.webSearch.focus());
        },
        toProductSearch() {
            this.productSearch = true;
            this.$nextTick(() => {
                this.$refs.queryInput.focus();
            });
        },
        async handleSearchUrl() {
            const qs = queryString.parse(window.location.search);
            const { q } = qs;
            this.query = q || "";
            if (this.query) {
                await this.doSearch(this.query, false);
                await this.getCategories();
            }
        },

        onNextPop(tag) {
            this.whichPop = validatePop(tag) || false;
        },
        onCloseHelp() {
            switch (this.whichPop) {
                case "intro-tutorial":
                    this.whichPop = validatePop("i");
                    return;
            }
            this.whichPop = false;
        },
        async doRecentSearch(term) {
            this.query = term;
            await this.doSearch(this.query);
        },
        async onSugSelected() {
            this.getCategories();
            if (!this.validateSearch(true)) return;
            await this.doSearch(this.query);
        },
    },
    computed: {
        baseUrl() {
            return window.location.href.split("?")[0];
        },
        hasSearchResults() {
            return this.numResults > 0;
        },
        numResults() {
            return this.filteredSearchResults.length;
        },
        filteredSearchResults() {
            if (this.filterTag) {
                return this.catRecords.filter(
                    record =>
                        record.resultCategory1 &&
                        record.resultCategory1 === this.filterTag
                );
            }
            if (this.filterStore) {
                return this.catRecords.filter(
                    record =>
                        record.resultEntity &&
                        record.resultEntity === this.filterStore
                );
            }
            return this.records;
        },
        altSearchUrls() {
            return altSearchProvidersUrls(this.query);
        },
    },
};
</script>

<style>
@import "./css/serp.css";
</style>
