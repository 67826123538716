import { getBranding } from "./branding";

const branding = getBranding();

// <!-- Google Analytics -->
(function(i, s, o, g, r, a, m) {
    i.GoogleAnalyticsObject = r;
    (i[r] =
        i[r] ||
        function() {
            (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
})(
    window,
    document,
    "script",
    "https://www.google-analytics.com/analytics.js",
    "ga"
);

ga("create", branding.GA_ID, "auto");
ga("send", "pageview");

export function trackGA(cat, action, label) {
    ga("send", {
        hitType: "event",
        eventCategory: cat,
        eventAction: action,
        eventLabel: label,
    });
}

// <!-- End Google Analytics -->
