/**
 * Autocomplete settings and utilities
 */

import memoize from "lodash.memoize";
import debounce from "lodash.debounce";
import queryString from "query-string";
import { SEARCH_API_URL } from "../../urls";

const ONE_MINUTE_MS = 60 * 1000;

// We will setup this to allow a call ever 10 minutes
const clearCache = debounce(
    () => suggestionProvider.cache.clear(),
    10 * ONE_MINUTE_MS
);

const suggestionProvider = memoize(async term => {
    clearCache(); // Make sure the cache doesnt get too big
    const qs = {
        q: term,
        perPage: 10,
    };
    const res = await fetch(`${SEARCH_API_URL}?${queryString.stringify(qs)}`);
    if (!res.ok) {
        return await res.json().catch(e => {
            return {
                error: "Bad Response",
                query: term,
                results: [],
            };
        });
    }
    const data = await res.json();
    return {
        ...data,
    };
});

export { suggestionProvider };
