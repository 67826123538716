import { getBranding } from "./branding";
import { sendMessageToExt } from "./storage";
import { isFirefox } from "./common";

const branding = getBranding();

const TOP_DOMAIN =
    (typeof branding !== "undefined" && branding.RootDomain) ||
    (function() {
        const parts = window.location.hostname.split(/\./);
        const domain =
            parts.length == 1
                ? window.location.hostname
                : parts.slice(-2).join(".");
        return (
            domain + (window.location.port ? `:${window.location.port}` : "")
        ).toLowerCase();
    })();

const CURRENT_HOST = `${new URL(window.location).origin}`;
const WEB_SEARCH_URL = `https://${branding.serviceSubdomain ||
    "services"}.${TOP_DOMAIN}/crx/search.php`;
const SEARCH_API_URL = "/api/search";
const SEARCHDOMAIN_API_URL = `/api/autocomplete?q=`;

function openUrl(toOpen, newTab = true) {
    if (!newTab) {
        return window.open(toOpen, "_self");
    }
    if (isFirefox) {
        return sendMessageToExt({ task: "openUrl", url: toOpen });
    }
    window.open(toOpen, "_blank");
}

function getTopDomain(url) {
    const { hostname } = new URL(url);
    return hostname;
}

async function getCurrentHostName() {
    const currentURL = await sendMessageToExt({ task: "getcurrentURL" });
    const topdomain = getTopDomain(currentURL.url);
    if (topdomain !== "newtab" && !topdomain.includes(branding.RootDomain)) {
        const res = await fetch(`${SEARCHDOMAIN_API_URL}${topdomain}`);
        if (!res.ok) {
            throw Error(res.statusText);
        }
        try {
            return (await res.json()).name;
        } catch (e) {
            return "";
        }
    }
}

export {
    WEB_SEARCH_URL,
    SEARCH_API_URL,
    openUrl,
    getCurrentHostName,
    TOP_DOMAIN,
    CURRENT_HOST,
};
